.homePic {
    padding-top: 5vh;
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 70vh;
    max-width: 50vw;
    
    // Add mask image for feather effect
    -webkit-mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
    mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
}



.section {
    // functionality
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: center;
    justify-content: space-between;
    // position
    padding-top: 4vw;
    padding-right: 3vw;
    max-height: 100vh;
}

.page-title-container {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
}

.page-title {
    padding-top: 5vh;
    padding-right: 5vw;
    padding-left: 7vw;
    margin-right: auto;
    font-size: 10vw;
    color:darkgray;
    // filter: drop-shadow(-2px 2px 10px #030c0d);
}

.page-subtitle {
    padding-top: 2vw;
    padding-right: 5vw;
    padding-left: 7vw;
    margin-right: auto;
    font-size: 3.5vw;
    color:darkgray;
    // filter: drop-shadow(-2px 2px 10px #030c0d);
}

.page-paragraph {
    font-size: 1vw;
    color:darkgray;
    max-width: 40vw;
    padding-left: 7vw;
    padding-top: 10vw;
}

@media screen and (max-width: 768px) {
    .section {
        flex-direction: column;
        align-items: center;
        justify-content: end;
        padding-right: 0vw;
    }

    .page-title-container {
        order: 1;
    }

    .page-title {
        font-size: 6vh;
    }

    .page-paragraph {
        order: 3;
        padding-top: 2vw;
        max-width: 80vw;
        font-size: 1.5vh;
    }

    .page-subtitle {
        font-size: 3vh;
    }

    .homePic {
        order: 2;
        max-height: 70vh;
        max-width: 100vw;
    }
}
.navbar {
  // position:absolute;
  z-index: 1;
  height: 8vw;
  max-height: 8vh;
  width: 100%;
  box-shadow: 0px 0px 1px #45505e;
  background: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  list-style-type: none;
}

.first-child {
  margin-right: auto;
}

.navlink {
  text-decoration: none;
  color: rgb(21, 49, 57);
  border-right: 3vw solid transparent;
  border-left: 3vw solid transparent;
  animation-name:slider;
  animation-duration:2s;
  transform:translateX(0%);
  font-family:Verdana, Geneva, Tahoma, sans-serif;
  font-size:1rem;
}

.navlink:hover {
  color: rgb(167, 217, 225);
}

  /* ANIMATIONS */

  @keyframes slider {
    from {transform: scale(85%);} 
    to {transform: scale(100%);}
  }

  @media screen and (max-width: 768px) {
    .navlink {
      font-size: 1.4vh;
    }
  }
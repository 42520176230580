@keyframes gravityDrop {
    0%, 10% {
      transform: translateY(-100px);
      opacity: 0; // Letters start off-screen and invisible
    }
    70% {
      transform: translateY(-10px);
      opacity: 1; // Rapid descent with increased speed
    }
    85% {
      transform: translateY(0px); // Slowing down as they approach the endpoint
    }
    95% {
      transform: translateY(-5px); // Small bounce effect
    }
    100% {
      transform: translateY(0);
      opacity: 1; // Letters fully visible and stationary
    }
  }
  
  .bouncing-text {
    display: inline-block;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size:2rem;
  
    span {
      display: inline-block;
      font-size: 2em;
      margin-right: 0.05em;
      opacity: 0; // Start each letter as invisible
      animation: gravityDrop 1.2s cubic-bezier(0.6, 0.04, 0.98, 0.335) forwards;
      animation-delay: var(--delay);
    }
  }
  
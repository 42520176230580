.homePic {
  padding-top: 5vh;
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 70vh;
  max-width: 50vw;  
  // Add mask image for feather effect
  -webkit-mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
  mask-image: linear-gradient(to right, transparent, black 10%, black 90%, transparent);
}

.video-player-settings {
  padding-top: 5vw;
  width: 40vw;
  margin: auto; // Center the video player if needed
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  border-radius: 2vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); // Subtle shadow
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out; // Smooth transition for hover effects

  &:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5); // Enhanced shadow for highlight effect
    transform: translateY(-5px); // Slight raise effect
  }
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  border-radius: 2vh; // Added to make the iframe's corners match the container
}

@media screen and (max-width: 768px) {
  .video-player-settings {
    padding-top: 15vh;
    width: 80vw;
  }
}

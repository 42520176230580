@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  
  .section-one {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .bouncing-text {
    display: inline-block;
    margin: auto;
    span {
      display: inline-block;
      font-size: 2em;
      margin-right: 0.05em;
      animation: bounce 1.4s ease infinite;
    }
  
}

    @media screen and (max-width: 768px) {
        .section-one {
            margin-top: 5vh;
        }
    }

  